<template>
  <div class="input__wrapper">
    <input
      :class="{ warn: warn }"
      :placeholder="placeholder"
      :value="value"
      @input="changeInput"
      @keydown="onkeydown"
      @blur="blurInput"
      @focus="onFocusInput"
      :maxlength="option?.max"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: String,
    placeholder: String,
    onlyNumber: Boolean,
    option: {
      max: Number,
    },
    disabled: String,
    warn: String,
  },
  data() {
    return {};
  },
  methods: {
    changeInput() {
      this.$emit('input');
    },
    onkeydown(event) {
      if (!this.onlyNumber) {
        return true;
      }
      if (Number(event.key) >= 0 && Number(event.key) <= 9) {
        return true;
      }
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Backspace') {
        return true;
      }
      event.preventDefault();
    },
    blurInput() {
      this.$emit('blur');
    },
    onFocusInput() {
      this.$emit('focus');
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  background: #f8f8fa;
  border: 0;
  border-radius: 10px;
  padding: 13px 16px;

  font-weight: 700;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #85808d;
}
input.warn {
  border: 1px solid #f24b4b;
}
input::placeholder {
  color: #1212144D;
  opacity: 1;
}

input:focus {
  outline: none;
  border: 1px solid #5d04d9;
}
</style>
