import { render } from "./Input.vue?vue&type=template&id=36cf4ce3&scoped=true"
import script from "./Input.vue?vue&type=script&lang=js"
export * from "./Input.vue?vue&type=script&lang=js"

import "./Input.vue?vue&type=style&index=0&id=36cf4ce3&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-36cf4ce3"
/* hot reload */
if (module.hot) {
  script.__hmrId = "36cf4ce3"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('36cf4ce3', script)) {
    api.reload('36cf4ce3', script)
  }
  
  module.hot.accept("./Input.vue?vue&type=template&id=36cf4ce3&scoped=true", () => {
    api.rerender('36cf4ce3', render)
  })

}

script.__file = "src/page/classApply/components/Input.vue"

export default script