<template>
  <div class="checkBox--block" :class="{ checked: checked }" v-if="type === 'block'" @click="onClickChekBox" />
  <div class="checkBox--line" :class="{ checked: checked }" v-if="type === 'line'" @click="onClickChekBox" />
</template>

<script>
export default {
  name: 'CheckBox',
  emits: ['click'],
  props: {
    type: String,
    checked: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onClickChekBox() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.checkBox--block {
  overflow: hidden;
  background-color: #e8e6ed;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-image: url('//res.tutoring.co.kr/res/images/tps/icon/ic_check_outlined_16px.png');
  background-position: center center;
  background-size: 16px 16px;
  content: '';
}
.checkBox--block.checked {
  background-color: #5d04d9;
}

.checkBox--line {
  position: relative;
  width: 15px;
  height: 15px;
  justify-content: center;
  cursor: pointer;

  // check icon
  &::after {
    display: block;
    position: absolute;
    left: 14%;
    top: 12%;
    width: 9px;
    height: 6px;
    border: 2px solid #e8e6ed;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
    content: '';
  }
}
.checkBox--line.checked {
  &::after {
    border: 2px solid #5d04d9;
    border-top: 0;
    border-right: 0;
  }
}
</style>
